@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap');

body {
    font-family: "Quicksand", Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f8f9fa;
    color: #16c39e;
}

h1, h2 {
    font-weight: bold;
}

button {
    transition: all 0.3s ease;
}

button:hover {
    transform: scale(1.05);
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}
